import Image from "next/image";
import { useIntl } from "react-intl";

function SocialMedia() {
  const intl = useIntl();

  return (
    <>
      <div
        id="social-media"
        className="social-media fixed start-6 top-3/4 z-10"
      >
        <div className="mb-3">
          <a
            href="https://www.instagram.com/likoverse/"
            target="_blank"
            rel="noopener"
            className="transition-opacity duration-150 hover:opacity-70"
          >
            <Image
              src="/img/icons/instagram-dark-gray.svg"
              width="32"
              height="32"
              alt="Instagram"
            />
          </a>
        </div>
        <div className="mb-3">
          <a
            href="https://twitter.com/LikoVerse"
            target="_blank"
            rel="noopener"
            className="transition-opacity duration-150 hover:opacity-70"
          >
            <Image
              src="/img/icons/twitter-dark-gray.svg"
              width="32"
              height="32"
              alt="Twitter"
            />
          </a>
        </div>
        <div className="mb-3">
          <a
            href="https://opensea.io/collection/likoverse-keys"
            target="_blank"
            rel="noopener"
            className="transition-opacity duration-150 hover:opacity-70"
          >
            <Image
              src="/img/icons/opensea-dark-gray.svg"
              width="32"
              height="32"
              alt="OpenSea"
            />
          </a>
        </div>
        <div className="mb-3">
          <a
            href="https://etherscan.io/address/0xdddf6cea8a8d72740472db2bcb99071cb108808e"
            target="_blank"
            rel="noopener"
            className="transition-opacity duration-150 hover:opacity-70"
          >
            <Image
              src="/img/icons/etherscan-dark-gray.svg"
              width="30"
              height="30"
              alt="Etherscan"
            />
          </a>
        </div>
      </div>
    </>
  );
}

export default SocialMedia;
