import Image from "next/image";
import { useState, useEffect } from "react";
import { useWindowWidth } from "@react-hook/window-size/throttled";
import { gsap } from "gsap";
import { throttle } from "lodash";
import Link from "next/link";
import { FormattedMessage } from "react-intl";
import { useRouter } from "next/router";
import { useIntl } from "react-intl";

function Header() {
  const windowWidth = useWindowWidth();
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const router = useRouter();
  const intl = useIntl();
  const [lang, setLang] = useState(router.locale === "he" ? "he_IL" : "en_US");
  let scrollY = 0;

  useEffect(() => {
    if (windowWidth >= 1280) {
      setIsMobile(false);
      setIsTablet(false);
      setIsDesktop(true);
    } else if (windowWidth >= 1024) {
      setIsMobile(false);
      setIsTablet(true);
      setIsDesktop(false);
    } else {
      setIsMobile(true);
      setIsTablet(false);
      setIsDesktop(false);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (isMobile || isTablet) {
      document.body.addEventListener("click", (event) => {
        if (!event.target.closest(".mobile-menu")) {
          const menu = document.querySelector("#menu");

          if (!menu.classList.contains("hidden")) {
            toggleMenu(event);
          }
        }
      });

      const header = document.querySelector(".header.header--fixed");
      const main = document.querySelector(
        ".section[data-header-menu-visibility='hidden']"
      );

      const menu = document.querySelector("#menu");
      let stopPosition = 2 * 62;

      if (main) {
        const rect = main.getBoundingClientRect();
        stopPosition = rect.bottom / 2;
      }

      window.addEventListener(
        "scroll",
        throttle((event) => {
          if (!menu.classList.contains("hidden")) {
            toggleMenu(event);
          }

          if (scrollY > window.scrollY) {
            if (window.scrollY > stopPosition) {
              if (!header.classList.contains("menu--shown")) {
                openFixedMenu(event);
              }
            } else {
              closeFixedMenu(event);
            }
          } else {
            closeFixedMenu(event);
          }

          scrollY = window.scrollY;
        }, 250)
      );
    }
  }, [isMobile, isTablet]);

  const openFixedMenu = (event) => {
    const header = document.querySelector(".header.header--fixed");

    if (header) {
      header.classList.add("menu--shown");

      gsap.fromTo(
        header,
        {
          opacity: "0",
          zIndex: "0",
          translateY: "-100%",
        },
        {
          opacity: "1",
          zIndex: "51",
          translateY: "0",
          duration: 0.15,
        }
      );
    }
  };

  const closeFixedMenu = (event) => {
    const header = document.querySelector(".header.header--fixed");

    if (header) {
      header.classList.remove("menu--shown");

      gsap.to(header, {
        opacity: "0",
        zIndex: "0",
        translateY: "-100%",
        duration: 0.15,
      });
    }
  };

  const toggleMenu = (event) => {
    event.stopPropagation();

    const menu = document.querySelector("#menu");

    if (menu.classList.contains("hidden")) {
      menu.classList.remove("hidden");

      gsap.to(".middle", {
        opacity: 0,
        duration: 0.15,
      });

      gsap.to(".top", {
        rotation: 45,
        duration: 0.3,
        ease: "back.inOut(1)",
        x: "+=2",
        scaleX: 1.05,
      });

      gsap.to(".bottom", {
        rotation: -45,
        duration: 0.3,
        ease: "back.inOut(1)",
        x: "+=2",
        scaleX: 1.05,
      });
    } else {
      menu.classList.add("hidden");

      gsap.to(".middle", {
        opacity: 1,
        duration: 0.15,
        delay: 0.15,
      });

      gsap.to(".top", {
        rotation: 0,
        duration: 0.3,
        ease: "back.inOut(1)",
        x: "-=2",
        scaleX: 1,
      });

      gsap.to(".bottom", {
        rotation: 0,
        duration: 0.3,
        ease: "back.inOut(1)",
        x: "-=2",
        scaleX: 1,
      });
    }
  };

  const toggleSubMenu = (event, menu) => {
    event.preventDefault();

    const activeMenuMenu = event.target.closest(".menu-item");
    const subMenus = document.querySelectorAll(".mobile-menu .sub-menu");

    subMenus.forEach((subMenu) => {
      if (activeMenuMenu.querySelector(".sub-menu") === subMenu) {
        subMenu.parentElement.classList.toggle("menu-item--open");
        subMenu.classList.toggle("hidden");
      } else {
        subMenu.parentElement.classList.remove("menu-item--open");
        subMenu.classList.add("hidden");
      }
    });
  };

  const closeSubMenus = (event) => {
    const subMenus = document.querySelectorAll(".mobile-menu .sub-menu");

    subMenus.forEach((subMenu) => {
      subMenu.parentElement.classList.remove("menu-item--open");
      subMenu.classList.add("hidden");
    });
  };

  const goToSection = (event) => {
    if (
      event.target.nodeName === "A" &&
      event.target.href.indexOf("#") !== -1
    ) {
      const anchorId = event.target.href.split("#")[1];
      const anchorElement = document.querySelector(`#${anchorId}`);

      if (anchorElement) {
        event.preventDefault();

        document.location.hash = `#${anchorId}`;

        anchorElement.scrollIntoView({
          behavior: "smooth",
        });
      }
    }

    if (isMobile) {
      toggleMenu(event);
      closeSubMenus(event);
    }
  };

  const scrollToFooter = (event) => {
    if (isMobile) {
      toggleMenu(event, null, true);
    }

    event.preventDefault();
    document.querySelector("#footer").scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <>
      <header className="header bg-transparent py-4 lg:py-6 top-0 absolute w-full">
        <div div className="mx-auto px-8 flex flex-row items-center relative">
          <div className="logo flex-grow xl:flex-grow-0 xl:fixed z-50">
            <a
              href={router.locale === "en" ? "/" : "/he"}
              className="transition-opacity duration-150 hover:opacity-70"
            >
              {isMobile && (
                <Image
                  src="/img/icons/logo.svg"
                  width="65.617"
                  height="75.814"
                  alt="LIKO"
                />
              )}

              {(isTablet || isDesktop) && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="95.846"
                  height="30"
                  viewBox="0 0 239.583 75.814"
                >
                  <g
                    id="Inserted_Image__x2B__Layer_21_xA0_Image_00000080188569917053926950000002188531875267399847_"
                    transform="translate(-131.034 -363.71)"
                  >
                    <g
                      id="Group_1051"
                      data-name="Group 1051"
                      transform="translate(131.034 363.71)"
                    >
                      <path
                        id="Path_7041"
                        data-name="Path 7041"
                        d="M406.631,412.1c1.345,1.411,2.625,2.618,3.743,3.956a18.982,18.982,0,0,1,1,22.263A13.168,13.168,0,0,1,403.239,444a11.052,11.052,0,0,1-9.549-2.267,15.187,15.187,0,0,1-5.206-7.728,8.873,8.873,0,0,0-2.011-3.648c-1.682-1.755-3.371-1.974-5.279-.5a29.075,29.075,0,0,0-3.349,3.085c-4.467,4.738-11.413,3.678-14.725,1.045a9.348,9.348,0,0,1-2.625-11.355,27.1,27.1,0,0,1,1.784-2.808c1.053-1.543,2.237-3,3.18-4.6a9.1,9.1,0,0,0-.066-9.783,21.788,21.788,0,0,1-1.945-3.9c-1.8-5.367.57-10.316,5.878-12.327a16.71,16.71,0,0,1,20.172,6.068,2.316,2.316,0,0,0,.38.322c.409-.344.848-.695,1.265-1.067,3.9-3.517,7.8-7.056,11.72-10.558a17.1,17.1,0,0,1,2.362-1.806A4.178,4.178,0,0,1,411.1,383.3a4.239,4.239,0,0,1-.556,5.856c-.877.855-1.828,1.638-2.749,2.449q-5.78,5.111-11.567,10.207a4.678,4.678,0,0,1-.563.373,7.63,7.63,0,0,0,4.431,3.692c2.106-1.711,4.284-3.466,6.441-5.25q8.84-7.326,17.664-14.681a4.759,4.759,0,0,1,6.865.38,5.249,5.249,0,0,1-.863,7.078c-7.45,5.835-14.849,11.742-22.271,17.628C407.479,411.386,407.041,411.759,406.631,412.1Zm-6.778,5.593c-2.405,1.908-4.826,2.31-6.975-.124a5.071,5.071,0,0,1-1.024-2.537,4.691,4.691,0,0,1,1.616-4.007c-1.4-1.082-2.7-2.069-4.029-3.093a5.567,5.567,0,0,1-1.206.958,4.716,4.716,0,0,1-2.12.548,5.275,5.275,0,0,1-4.241-4.54,3.323,3.323,0,0,1,1.243-3.107c.453-.351.841-.775,1.309-1.221-.738-1.111-1.36-2.164-2.106-3.129a3.514,3.514,0,0,0-4.687-1,4.88,4.88,0,0,0-2.164,6.975c.695,1.287,1.44,2.544,2.055,3.868a8.117,8.117,0,0,1-2.654,10.134c-1.082.8-2.223,1.521-3.224,2.413a6.784,6.784,0,0,0-2.413,4.716,3.474,3.474,0,0,0,2.237,3.6c1.879.782,3.634.322,5.14-1.492a35.8,35.8,0,0,0,2.091-3.02,15.053,15.053,0,0,1,4.591-4.745,5.113,5.113,0,0,1,7.034,1.192,42.155,42.155,0,0,1,2.427,4.036,23.081,23.081,0,0,0,1.828,2.968,3.649,3.649,0,0,0,3.89,1.418,4.011,4.011,0,0,0,3.093-3.012C402.354,422.668,401.418,420.233,399.854,417.7Z"
                        transform="translate(-192.475 -368.475)"
                        fill="#fff"
                        className="logo-text"
                      />
                      <path
                        id="Path_7042"
                        data-name="Path 7042"
                        d="M328.8,438.736a9.109,9.109,0,0,1-6.017-2.033,5.675,5.675,0,0,1-1.667-2.376,41.339,41.339,0,0,1-1.228-4.738c-.322-1.36-.541-2.749-.892-4.1a2.923,2.923,0,0,0-2.384-2.289c-.855-.175-1.711-.366-2.566-.556a6.3,6.3,0,0,0-6.054,1.93c-1.506,1.47-3,2.939-4.577,4.336a7.623,7.623,0,0,1-3.9,1.85A7.461,7.461,0,0,1,291.9,427.2a8.4,8.4,0,0,1-.622-7.889,32.833,32.833,0,0,1,1.667-3.275c1.1-2,2.3-3.934,3.349-5.959a11.766,11.766,0,0,0,1.184-3.268,5.182,5.182,0,0,0-1.492-4.635,21.456,21.456,0,0,1-2.939-4.094,7.24,7.24,0,0,1-.782-4.423,6.674,6.674,0,0,1,6.3-6.185,10.3,10.3,0,0,1,7.962,2.552,15.675,15.675,0,0,1,5.762,14.469c-.044.351-.117.695-.146,1.038-.1,1.426.87,2.237,2.186,1.682a18.322,18.322,0,0,0,3.824-2.054,4.441,4.441,0,0,0,1.864-4.614c-.154-.8-.154-1.631-.271-2.435a18.093,18.093,0,0,1,2.2-11.676,17.471,17.471,0,0,1,9.322-7.6,18.815,18.815,0,0,1,4.065-1.016c3.049-.461,5.242,1.082,6.748,3.473a5.836,5.836,0,0,1-.49,6.9,19.725,19.725,0,0,1-5.834,5.491,9.037,9.037,0,0,0-3.707,4.087,4,4,0,0,0,.095,4.3c2.369,3.422-.314,8.92-3.912,9.534a18.913,18.913,0,0,1-4.167.2,15.574,15.574,0,0,0-5.9.424,2.581,2.581,0,0,0-2.04,2.815,2.883,2.883,0,0,0,3.005,2.172,8.246,8.246,0,0,0,2.691-.651,22.026,22.026,0,0,1,10.96-1.7c4.767.344,7,5.045,6.529,8.255a31.336,31.336,0,0,1-1.462,5.754A45.783,45.783,0,0,1,335.3,434.8C333.81,437.7,332.048,438.67,328.8,438.736Z"
                        transform="translate(-173.927 -367.479)"
                        fill="#fff"
                        className="logo-text"
                      />
                      <path
                        id="Path_7043"
                        data-name="Path 7043"
                        d="M199.12,410.646a11.12,11.12,0,0,1,1.682-6.229,20.9,20.9,0,0,1,8.328-7.311c2.208-1.192,4.431-2.362,6.6-3.634a20.889,20.889,0,0,0,5.937-5.125,11.418,11.418,0,0,0,2.734-6.339c.278-4.219,2.647-6.668,6.514-7.969a13.213,13.213,0,0,1,7.026-.885,4.93,4.93,0,0,1,4.087,4.423,9.745,9.745,0,0,1-1.645,6.661,31.551,31.551,0,0,1-7.589,7.458c-2.047,1.543-4.241,2.9-6.39,4.306a14.5,14.5,0,0,0-4.672,4.884,5.058,5.058,0,0,0,4.482,7.443,13.134,13.134,0,0,0,5.447-1.755c1.7-.892,3.378-1.864,5.067-2.793a7.5,7.5,0,0,1,4.365-1.046,6.407,6.407,0,0,1,5.725,4.248c2.369,5.864-2.062,13.446-8.335,14.2a10.284,10.284,0,0,1-3.122-.263,37.957,37.957,0,0,0-19.032,1.287,13.136,13.136,0,0,1-16.992-9.9A15.608,15.608,0,0,1,199.12,410.646Z"
                        transform="translate(-149.339 -366.199)"
                        fill="#fff"
                        className="logo-text"
                      />
                      <path
                        id="Path_7044"
                        data-name="Path 7044"
                        d="M182.313,363.776c4.906-.051,9.439.943,13.263,3.963,4.226,3.334,5.557,7.377,4.211,12.057a5.751,5.751,0,0,1-2.9,3.561c-.965.534-1.974,1.009-2.99,1.455a52.929,52.929,0,0,0-23.258,19.054,50.592,50.592,0,0,0-7.414,17.094c-1.543,6.778-5.608,11.362-12.444,13.095a17.036,17.036,0,0,1-14.908-3.188c-4.277-3.2-5.25-7.8-4.709-12.846a30.645,30.645,0,0,1,2.661-8.671,91.647,91.647,0,0,1,20.18-29.655c4.577-4.489,9.322-8.876,15.01-12.013a61.962,61.962,0,0,1,6.829-3.129A14.415,14.415,0,0,1,182.313,363.776Zm-46.164,53.637c.124,6.471,5.22,9.322,9.666,8.664a12.462,12.462,0,0,0,8.7-5.652,21.878,21.878,0,0,0,2.588-6.09c1.7-5.557,4.745-10.4,8.225-14.981,6.58-8.657,14.126-16.2,24.3-20.662a8.108,8.108,0,0,0,2.172-1.448,4.134,4.134,0,0,0-.037-6.412,11.723,11.723,0,0,0-2.6-1.74,16.548,16.548,0,0,0-9.585-1.309c-4.065.541-7.487,2.544-10.777,4.8a93.86,93.86,0,0,0-22.227,21.949,61.642,61.642,0,0,0-9.044,16.151A22.237,22.237,0,0,0,136.149,417.412Z"
                        transform="translate(-131.034 -363.71)"
                        fill="#fff"
                        className="logo-text"
                      />
                      <path
                        id="Path_7045"
                        data-name="Path 7045"
                        d="M272.363,395.834a6.791,6.791,0,0,1,7.158,8.467c-.314,1.426-.738,2.822-1.111,4.233-.738,2.793-.263,5.571.139,8.35a20.4,20.4,0,0,1-1.338,11.684c-2.515,5.447-6.244,9.695-12.312,11.369a7.31,7.31,0,0,1-4.643-.154A5.907,5.907,0,0,1,256.249,434a7.217,7.217,0,0,1,3.517-6.463,19.857,19.857,0,0,0,7.129-6.6,12.074,12.074,0,0,0,2.033-6.069,6.605,6.605,0,0,0-2.011-5.052,23.257,23.257,0,0,1-1.769-1.937,7.019,7.019,0,0,1,2.062-10.565A9.205,9.205,0,0,1,272.363,395.834Z"
                        transform="translate(-164.698 -372.343)"
                        fill="#fff"
                        className="logo-text"
                      />
                      <path
                        id="Path_7046"
                        data-name="Path 7046"
                        d="M445.121,425.53a3.637,3.637,0,0,1,3.67,3.634,3.582,3.582,0,0,1-3.473,3.458,3.686,3.686,0,0,1-3.758-3.568A3.525,3.525,0,0,1,445.121,425.53Z"
                        transform="translate(-214.521 -380.331)"
                        fill="#fff"
                        className="logo-text"
                      />
                      <path
                        id="Path_7047"
                        data-name="Path 7047"
                        d="M188.232,373.75a8.872,8.872,0,0,1,1.733.68,2.189,2.189,0,0,1,.3,3.787,11.476,11.476,0,0,1-1.894,1.25c-7.75,4.416-13.9,10.594-19.295,17.577-1.813,2.354-3.385,4.891-5.089,7.326a11.789,11.789,0,0,1-1.455,1.733,3.512,3.512,0,0,1-4.35.409,3.466,3.466,0,0,1-1.484-3.955,21.386,21.386,0,0,1,3.773-7.26c5.622-7.333,12.1-13.775,20.121-18.491a54.354,54.354,0,0,1,6.207-2.771A7.1,7.1,0,0,1,188.232,373.75Z"
                        transform="translate(-137.896 -366.409)"
                        fill="#fff"
                        className="logo-text"
                      />
                      <path
                        id="Path_7048"
                        data-name="Path 7048"
                        d="M145.3,433.1a19.771,19.771,0,0,1,3.634-8.723,5.522,5.522,0,0,1,1.74-1.426,2.618,2.618,0,0,1,1.477-.351,3.526,3.526,0,0,1,3.093,4.862c-.76,2.011-1.579,4.007-2.493,5.952a8.772,8.772,0,0,1-1.784,2.552A3.391,3.391,0,0,1,145.3,433.1Z"
                        transform="translate(-134.866 -379.54)"
                        fill="#fff"
                        className="logo-text"
                      />
                    </g>
                  </g>
                </svg>
              )}
            </a>
          </div>
          <div className="spacer w-[150px]"></div>
          <div className="desktop-menu hidden xl:block flex-grow text-center xl:-ml-32">
            <nav role="navigation">
              <ul>
                <li className="menu-item menu-item--about inline-block pe-12 py-1 relative">
                  <a
                    href={router.locale === "en" ? "/about" : "/he/about"}
                    className="uppercase font-title relative z-10"
                  >
                    About
                  </a>
                  <ul className="sub-menu font-light leading-tight text-white text-start absolute top-8 start-0 w-36">
                    <li className="mb-4">
                      <a
                        href={
                          router.locale === "en"
                            ? "/about/#what-is-liko"
                            : "/he/about/#what-is-liko"
                        }
                        onClick={(event) => goToSection(event)}
                        className=""
                      >
                        What is Liko?
                      </a>
                    </li>
                    <li className="mb-4">
                      <a
                        href={
                          router.locale === "en"
                            ? "/about/#the-wolff"
                            : "/he/about/#the-wolff"
                        }
                        onClick={(event) => goToSection(event)}
                        className=""
                      >
                        The Wolff
                      </a>
                    </li>
                    <li className="mb-4">
                      <a
                        href={
                          router.locale === "en"
                            ? "/about/#board-of-directors"
                            : "/he/about/#board-of-directors"
                        }
                        onClick={(event) => goToSection(event)}
                        className=""
                      >
                        Board of Directors
                      </a>
                    </li>
                    <li className="mb-4">
                      <a
                        href={
                          router.locale === "en"
                            ? "/about/#executive-leadership"
                            : "/he/about/#executive-leadership"
                        }
                        onClick={(event) => goToSection(event)}
                        className=""
                      >
                        Executive Leadership
                      </a>
                    </li>
                  </ul>
                </li>

                <li className="menu-item menu-item--science inline-block pe-12 py-1 relative">
                  <a
                    href={router.locale === "en" ? "/business" : "/he/business"}
                    className="uppercase font-title relative z-10"
                  >
                    Business
                  </a>
                  <ul className="sub-menu font-light leading-tight text-white text-start absolute top-8 start-0 w-36">
                    <li className="mb-3">
                      <a
                        href={
                          router.locale === "en"
                            ? "/business/#business-model"
                            : "/he/business/#business-model"
                        }
                        onClick={(event) => goToSection(event)}
                        className=""
                      >
                        Business Model
                      </a>
                    </li>
                    <li className="mb-3">
                      <a
                        href={
                          router.locale === "en"
                            ? "/business/#keys"
                            : "/he/business/#keys"
                        }
                        onClick={(event) => goToSection(event)}
                        className=""
                      >
                        Keys
                      </a>
                    </li>
                    <li className="mb-3">
                      <a
                        href={
                          router.locale === "en"
                            ? "/business/#investor-keys"
                            : "/he/business/#investor-keys"
                        }
                        onClick={(event) => goToSection(event)}
                        className=""
                      >
                        Investor Keys
                      </a>
                    </li>
                    <li className="mb-3">
                      <a
                        href={
                          router.locale === "en"
                            ? "/business/#together"
                            : "/he/business/#together"
                        }
                        onClick={(event) => goToSection(event)}
                        className=""
                      >
                        Together & United
                      </a>
                    </li>
                    <li className="mb-3">
                      <a
                        href={
                          router.locale === "en"
                            ? "/business/#involvement"
                            : "/he/business/#involvement"
                        }
                        onClick={(event) => goToSection(event)}
                        className=""
                      >
                        Involvement
                      </a>
                    </li>
                    <li className="mb-3">
                      <a
                        href={
                          router.locale === "en"
                            ? "/business/#collection"
                            : "/he/business/#collection"
                        }
                        onClick={(event) => goToSection(event)}
                        className=""
                      >
                        Collection
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="menu-item menu-item--treatments inline-block pe-12 py-1 relative">
                  <a
                    href={router.locale === "en" ? "/world" : "/he/world"}
                    className="uppercase font-title relative z-10"
                  >
                    Liko's World
                  </a>
                  <ul className="sub-menu font-light leading-tight	text-white text-start absolute top-8 start-0 w-36">
                    <li className="mb-4">
                      <a
                        href={
                          router.locale === "en"
                            ? "/world/#fashion"
                            : "/he/world/#fashion"
                        }
                        onClick={(event) => goToSection(event)}
                        className=""
                      >
                        Fashion
                      </a>
                    </li>
                    <li className="mb-4">
                      <a
                        href={
                          router.locale === "en"
                            ? "/world/#merchandise"
                            : "/he/world/#merchandise"
                        }
                        onClick={(event) => goToSection(event)}
                        className=""
                      >
                        Merchandise
                      </a>
                    </li>
                    <li className="mb-4">
                      <a
                        href={
                          router.locale === "en"
                            ? "/world/#gaming"
                            : "/he/world/#gaming"
                        }
                        onClick={(event) => goToSection(event)}
                        className=""
                      >
                        Gaming
                      </a>
                    </li>
                    <li className="mb-4">
                      <a
                        href={
                          router.locale === "en"
                            ? "/world/#show"
                            : "/he/world/#show"
                        }
                        onClick={(event) => goToSection(event)}
                        className=""
                      >
                        The Liko Show
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="menu-item inline-block py-1 relative">
                  <a
                    href="#"
                    className="uppercase font-title relative z-10"
                    onClick={(event) => scrollToFooter(event)}
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="menu-button ms-2 xl:hidden relative z-[51]">
            <button
              type="button"
              onClick={(event) => toggleMenu(event)}
              className="transition-opacity duration-150 hover:opacity-70"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="#ffffff"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  className="top"
                  d="M4 6h16"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  className="middle"
                  d="M4 12h16"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  className="bottom"
                  d="M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
      </header>

      {(isMobile || isTablet) && (
        <div className="header header--fixed bg-white py-4 top-0 fixed w-full opacity-0 z-0 shadow-md">
          <div div className="mx-auto px-8 flex flex-row items-center relative">
            <div className="logo flex-grow">
              <a
                href={router.locale === "en" ? "/" : "/he"}
                className="transition-opacity duration-150 hover:opacity-70"
              >
                <Image
                  src="/img/icons/logo.svg"
                  width="66.051"
                  height="20.674"
                  alt="LIKO"
                />
              </a>
            </div>
            <div className="menu-button">
              <button
                type="button"
                onClick={(event) => toggleMenu(event)}
                className="transition-opacity duration-150 hover:opacity-70"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  stroke="#636466"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="top"
                    d="M4 6h16M4"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="middle"
                    d="M4 12h16M4"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="bottom"
                    d="M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}

      <div
        id="menu"
        className="menu mobile-menu fixed inset-x-0 lg:inset-x-auto lg:inset-y-0 lg:top-0 lg:end-0 lg:w-96 z-50 bg-white bg-opacity-100 shadow-md hidden mt-15 lg:mt-0 lg:pt-16"
      >
        <div div className="container mx-auto p-8 relative">
          <nav role="navigation">
            <ul>
              <li className="menu-item font-title text-epm-gray-700 tracking-wide border-b-1 border-epm-gray-500 mb-3 pb-3">
                <a
                  href={router.locale === "en" ? "/about" : "/he/about"}
                  className="block uppercase cursor-pointer relative font-title"
                  onClick={(event) =>
                    toggleSubMenu(event, document.querySelector(".menu--about"))
                  }
                >
                  About
                </a>
                <ul className="sub-menu menu--about hidden">
                  <li className="text-sm my-2">
                    <a
                      href={
                        router.locale === "en"
                          ? "/about/#what-is-liko"
                          : "/he/about/#what-is-liko"
                      }
                      onClick={(event) => goToSection(event)}
                    >
                      What is Liko?
                    </a>
                  </li>
                  <li className="text-sm my-2">
                    <a
                      href={
                        router.locale === "en"
                          ? "/about/#the-wolff"
                          : "/he/about/#the-wolff"
                      }
                      onClick={(event) => goToSection(event)}
                    >
                      The Wolff
                    </a>
                  </li>
                  <li className="text-sm my-2">
                    <a
                      href={
                        router.locale === "en"
                          ? "/about/#board-of-directors"
                          : "/he/about/#board-of-directors"
                      }
                      onClick={(event) => goToSection(event)}
                    >
                      Board of Directors
                    </a>
                  </li>
                  <li className="text-sm my-2">
                    <a
                      href={
                        router.locale === "en"
                          ? "/about/#executive-leadership"
                          : "/he/about/#executive-leadership"
                      }
                      onClick={(event) => goToSection(event)}
                    >
                      Executive Leadership
                    </a>
                  </li>
                </ul>
              </li>
              <li className="menu-item font-title text-epm-gray-700 tracking-wide border-b-1 border-epm-gray-500 mb-3 pb-3">
                <a
                  href={router.locale === "en" ? "/business" : "/he/business"}
                  className="block uppercase cursor-pointer relative font-title"
                  onClick={(event) =>
                    toggleSubMenu(
                      event,
                      document.querySelector(".menu--business")
                    )
                  }
                >
                  Business
                </a>
                <ul className="sub-menu menu--business hidden">
                  <li className="text-sm my-2">
                    <a
                      href={
                        router.locale === "en"
                          ? "/business/#business-model"
                          : "/he/business/#business-model"
                      }
                      onClick={(event) => goToSection(event)}
                      className="transition-opacity duration-150 hover:opacity-70"
                    >
                      Business Model
                    </a>
                  </li>
                  <li className="text-sm my-2">
                    <a
                      href={
                        router.locale === "en"
                          ? "/business/#keys"
                          : "/he/business/#keys"
                      }
                      onClick={(event) => goToSection(event)}
                      className="transition-opacity duration-150 hover:opacity-70"
                    >
                      Keys
                    </a>
                  </li>
                  <li className="text-sm my-2">
                    <a
                      href={
                        router.locale === "en"
                          ? "/business/#investor-keys"
                          : "/he/business/#investor-keys"
                      }
                      onClick={(event) => goToSection(event)}
                      className="transition-opacity duration-150 hover:opacity-70"
                    >
                      Investor Keys
                    </a>
                  </li>
                  <li className="text-sm my-2">
                    <a
                      href={
                        router.locale === "en"
                          ? "/business/#together"
                          : "/he/business/#together"
                      }
                      onClick={(event) => goToSection(event)}
                      className="transition-opacity duration-150 hover:opacity-70"
                    >
                      Together & United
                    </a>
                  </li>
                  <li className="text-sm my-2">
                    <a
                      href={
                        router.locale === "en"
                          ? "/business/#involvement"
                          : "/he/business/#involvement"
                      }
                      onClick={(event) => goToSection(event)}
                      className="transition-opacity duration-150 hover:opacity-70"
                    >
                      Involvement
                    </a>
                  </li>
                  <li className="text-sm my-2">
                    <a
                      href={
                        router.locale === "en"
                          ? "/business/#collection"
                          : "/he/business/#collection"
                      }
                      onClick={(event) => goToSection(event)}
                      className="transition-opacity duration-150 hover:opacity-70"
                    >
                      Collection
                    </a>
                  </li>
                </ul>
              </li>

              <li className="menu-item font-title text-epm-gray-700 tracking-wide border-b-1 border-epm-gray-500 mb-3 pb-3">
                <a
                  href={router.locale === "en" ? "/world" : "/he/world"}
                  className="block uppercase cursor-pointer relative font-title"
                  onClick={(event) =>
                    toggleSubMenu(event, document.querySelector(".menu--world"))
                  }
                >
                  Liko's World
                </a>
                <ul className="sub-menu menu--world hidden">
                  <li className="text-sm my-2">
                    <a
                      href={
                        router.locale === "en"
                          ? "/world/#fashion"
                          : "/he/world/#fashion"
                      }
                      onClick={(event) => goToSection(event)}
                      className="transition-opacity duration-150 hover:opacity-70"
                    >
                      Fashion
                    </a>
                  </li>
                  <li className="text-sm my-2">
                    <a
                      href={
                        router.locale === "en"
                          ? "/world/#merchandise"
                          : "/he/world/#merchandise"
                      }
                      onClick={(event) => goToSection(event)}
                      className="transition-opacity duration-150 hover:opacity-70"
                    >
                      Merchandise
                    </a>
                  </li>
                  <li className="text-sm my-2">
                    <a
                      href={
                        router.locale === "en"
                          ? "/world/#gaming"
                          : "/he/world/#gamingoard"
                      }
                      onClick={(event) => goToSection(event)}
                      className="transition-opacity duration-150 hover:opacity-70"
                    >
                      Gaming
                    </a>
                  </li>
                  <li className="text-sm my-2">
                    <a
                      href={
                        router.locale === "en"
                          ? "/world/#show"
                          : "/he/world/#show"
                      }
                      onClick={(event) => goToSection(event)}
                      className="transition-opacity duration-150 hover:opacity-70"
                    >
                      The Liko Show
                    </a>
                  </li>
                </ul>
              </li>

              <li className="font-title text-epm-gray-700 tracking-wide ">
                <a
                  href="#"
                  className="block uppercase cursor-pointer relative font-title"
                  onClick={(event) => scrollToFooter(event)}
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Header;
