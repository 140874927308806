import Image from "next/image";
import { useState, useEffect } from "react";
import { gsap } from "gsap";
import { useWindowWidth } from "@react-hook/window-size/throttled";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import Link from "next/link";
import { useIntl } from "react-intl";
import { useRouter } from "next/router";

function Footer({}) {
  const windowWidth = useWindowWidth();
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const router = useRouter();
  const intl = useIntl();

  useEffect(() => {
    if (windowWidth >= 1280) {
      setIsMobile(false);
      setIsTablet(false);
      setIsDesktop(true);
    } else if (windowWidth >= 1024) {
      setIsMobile(false);
      setIsTablet(true);
      setIsDesktop(false);
    } else {
      setIsMobile(true);
      setIsTablet(false);
      setIsDesktop(false);
    }
  }, [windowWidth]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const tl = gsap.timeline();

    tl.add("contact-form-submit");
    tl.fromTo(
      ".contact__form",
      {
        opacity: 1,
      },
      {
        opacity: 0,
        duration: 0.25,
      },
      "contact-form-submit"
    );
    tl.fromTo(
      ".contact__thanks",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.25,
        zIndex: 11,
      },
      "contact-form-submit"
    );
  };

  const scrollToHome = (event) => {
    event.preventDefault();
    document.querySelector("#main").scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <footer
      id="footer"
      className="section bg-liko-gray-300 min-h-screen lg:flex lg:flex-wrap lg:content-center"
      data-side-menu-visibility="hidden"
      data-logo-color="dark"
    >
      <div className="animate opacity-0 container mx-auto min-h-screen lg:min-h-0 lg:h-103 px-8 lg:px-16 xl:px-24 2xl:px-8 pt-12 pb-4 lg:py-8 lg:ps-24 xl:ps-56 2xl:ps-8 lg:flex lg:flex-col lg:justify-between">
        <div className="lg:flex lg:flex-row lg:justify-between relative">
          <div className="contact__form flex-grow lg:flex-grow-0 relative z-10 lg:w-96 2xl:w-109 xl:pe-0">
            <div className="mb-4 lg:mb-8">
              <h2 className="font-title text-4.5xl lg:text-6xl xl:text-5.5xl 2xl:text-7.5xl leading-snug lg:leading-normal xl:leading-snug text-epm-gray-700">
                Join our community
              </h2>
              <p className="mt-4">
                Send us an email at{" "}
                <a href="mailto:info@likoverse.com" className="underline">
                  info@likoverse.com
                </a>
              </p>
            </div>
            {/*<div className="mb-8 lg:mb-0">
              <Formik
                initialValues={{ firstName: "", lastName: "", email: "" }}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = "Required";
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = "Invalid email address";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(async () => {
                    setSubmitting(false);

                    fetch(
                      "https://hooks.zapier.com/hooks/catch/5896166/b90eib7/",
                      {
                        method: "POST",
                        mode: "no-cors",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                          firstName: values.firstName,
                          lastName: values.lastName,
                          email: values.email,
                          phone: values.phone,
                          locale: router.locale,
                        }),
                      }
                    )
                      .then((response) => {
                        const tl = gsap.timeline();

                        tl.add("contact-form-submit");
                        tl.fromTo(
                          ".contact__form",
                          {
                            opacity: 1,
                          },
                          {
                            opacity: 0,
                            duration: 0.25,
                          },
                          "contact-form-submit"
                        );
                        tl.fromTo(
                          ".contact__thanks",
                          {
                            opacity: 0,
                          },
                          {
                            opacity: 1,
                            duration: 0.25,
                            zIndex: 11,
                          },
                          "contact-form-submit"
                        );
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                  }, 400);
                }}
              >
                {({ errors, isSubmitting }) => (
                  <Form>
                    <div className="flex flex-col">
                      <div className="mb-4 lg:mb-6 leading-8 lg:flex">
                        <Field
                          type="text"
                          name="firstName"
                          placeholder={intl.formatMessage({
                            id: "contact.firstName",
                            defaultMessage: "First Name*",
                          })}
                          className="w-full font-light rounded-3xl px-5 py-2 me-4 mb-4 lg:mb-0"
                        />
                        <Field
                          type="text"
                          name="lastName"
                          placeholder={intl.formatMessage({
                            id: "contact.lastName",
                            defaultMessage: "Last Name*",
                          })}
                          className="w-full font-light rounded-3xl px-5 py-2"
                        />
                      </div>
                      <div className="mb-4 lg:mb-6 leading-8 flex-1">
                        <Field
                          type="email"
                          name="email"
                          placeholder={intl.formatMessage({
                            id: "contact.email",
                            defaultMessage: "Email Address*",
                          })}
                          className={classNames(
                            "w-full font-light rounded-3xl px-5 py-2",
                            {
                              "outline-2 outline-red-500": errors.email,
                            }
                          )}
                        />
                      </div>
                      <div className="mb-4 lg:mb-6 leading-8 flex-1">
                        <Field
                          type="tel"
                          name="phone"
                          placeholder={intl.formatMessage({
                            id: "contact.phone",
                            defaultMessage: "Phone Number",
                          })}
                          className={classNames(
                            "w-full font-light rounded-3xl px-5 py-2",
                            {
                              "outline-2 outline-red-500": errors.phone,
                            }
                          )}
                        />
                      </div>
                      <div className="mb-12 lg:mb-20">
                        <button
                          type="submit"
                          className="w-full lg:w-auto font-title text-center uppercase transition-opacity duration-150 hover:opacity-70 bg-epm-gray-700 text-xl border-3 border-epm-gray-700 text-white font-light rounded-3xl lg:px-16 py-1"
                          disabled={isSubmitting}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>*/}
          </div>
          <div className="contact__thanks mb-12 opacity-0 absolute top-0 start-0 z-0">
            <div className="mb-8">
              <h2 className="font-title text-4.5xl lg:text-6xl xl:text-4.5xl 2xl:text-6xl leading-snug lg:leading-normal xl:leading-snug text-white">
                Great :)
              </h2>
              <p className="text-xs lg:text-lg font-title text-white mt-4">
                Thank You!
              </p>
            </div>
            <div>
              {/* <a
                href="#main"
                onClick={(event) => scrollToHome(event)}
                className="font-title text-md text-epm-gray-700 underline uppercase"
              >
                Back home
              </a> */}
            </div>
          </div>
          <div className="flex flex-row">
            <div className="flex-grow">
              <div className="mb-4 leading-8">
                <div className="text-white font-bold uppercase">
                  Information
                </div>
                <div className="">
                  <a
                    href="mailto:info@likoverse.com"
                    className="underline transition-opacity duration-150 hover:opacity-70"
                  >
                    info@likoverse.com
                  </a>
                </div>
                <div className="">
                  Collab relations:{" "}
                  <a
                    href="mailto:office@likoverse.com"
                    className="underline transition-opacity duration-150 hover:opacity-70"
                  >
                    office@likoverse.com
                  </a>
                </div>
              </div>
              <div>
                {/*<div className="mb-4 leading-8">
                  <div className="text-white font-bold uppercase">
                    Israel Headquarters
                  </div>
                  <div className="">
                    5 Kinneret St.
                    <br />
                    14th floor
                    <br />
                    BSR 3 Tower
                    <br />
                    B.B.C. Business Center
                    <br />
                    Bnei Brak 5126237
                  </div>
                </div>*/}
                <div className="mb-4 leading-8">
                  <div className="text-white font-bold uppercase">
                    US Office
                  </div>
                  <div className="">
                    401 Ryland Street Suite-A
                    <br />
                    Reno NV 89502, USA
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:hidden">
              <div className="mb-3">
                <a
                  href="https://www.instagram.com/likoverse/"
                  target="_blank"
                  rel="noopener"
                  className="transition-opacity duration-150 hover:opacity-70"
                >
                  <Image
                    src="/img/icons/instagram-dark-gray.svg"
                    width="32"
                    height="32"
                    alt="Instagram"
                  />
                </a>
              </div>
              <div className="mb-3">
                <a
                  href="https://twitter.com/LikoVerse"
                  target="_blank"
                  rel="noopener"
                  className="transition-opacity duration-150 hover:opacity-70"
                >
                  <Image
                    src="/img/icons/twitter-dark-gray.svg"
                    width="32"
                    height="32"
                    alt="Twitter"
                  />
                </a>
              </div>
              <div className="mb-3">
                <a
                  href="https://opensea.io/collection/likoverse-keys"
                  target="_blank"
                  rel="noopener"
                  className="transition-opacity duration-150 hover:opacity-70"
                >
                  <Image
                    src="/img/icons/opensea-dark-gray.svg"
                    width="32"
                    height="32"
                    alt="OpenSea"
                  />
                </a>
              </div>
              <div className="mb-3">
                <a
                  href="https://etherscan.io/address/0xdddf6cea8a8d72740472db2bcb99071cb108808e"
                  target="_blank"
                  rel="noopener"
                  className="transition-opacity duration-150 hover:opacity-70"
                >
                  <Image
                    src="/img/icons/etherscan-dark-gray.svg"
                    width="30"
                    height="30"
                    alt="Etherscan"
                  />
                </a>
              </div>
            </div>
          </div>
          <div id="footer_menu" className="footer-menu hidden lg:block">
            <div div className="container mx-auto px-8 lg:px-0 relative">
              <nav role="navigation">
                <ul className="leading-8">
                  <li className="font-title text-epm-gray-700 tracking-wide pb-4">
                    <a
                      href={router.locale === "en" ? "/about" : "/he/about"}
                      className="block uppercase underline transition-opacity duration-150 hover:opacity-70"
                    >
                      About
                    </a>
                  </li>
                  <li className="font-title text-epm-gray-700 tracking-wide pb-4">
                    <a
                      href={
                        router.locale === "en" ? "/business" : "/he/business"
                      }
                      className="block uppercase underline transition-opacity duration-150 hover:opacity-70"
                    >
                      Business
                    </a>
                  </li>
                  <li className="font-title text-epm-gray-700 tracking-wide pb-4">
                    <a
                      href={router.locale === "en" ? "/world" : "/he/world"}
                      className="block uppercase underline"
                    >
                      Liko's World
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="copyright text-xs lg:text-sm font-title text-epm-gray-700">
          <a
            href={
              router.locale === "en" ? "/privacy-policy" : "/he/privacy-policy"
            }
            className="underline cursor-pointer transition-opacity duration-150 hover:opacity-70"
          >
            Privacy Policy
          </a>
          <span> | </span>
          <a
            href={router.locale === "en" ? "/terms-of-use" : "/he/terms-of-use"}
            className="underline cursor-pointer transition-opacity duration-150 hover:opacity-70"
          >
            Terms of Use
          </a>
          <span> | </span>
          <a
            href={
              router.locale === "en"
                ? "/terms-of-service"
                : "/he/terms-of-service"
            }
            className="underline cursor-pointer transition-opacity duration-150 hover:opacity-70"
          >
            Terms of Service
          </a>
          <span className="hidden lg:inline"> | </span>
          <span className="block mt-1.5 lg:inline-block lg:mt-0">
            Copyright ©2021 LIKO Group, Inc. All Rights Reserved
          </span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
